var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getPermission('service:create'))?_c('v-layout',[_c('v-col',{staticClass:"custom-content-container pt-0",attrs:{"cols":"12","md":"9","sm":"9"}},[(_vm.errors.length)?_c('div',{staticClass:"iv-custom-field pt-0 width-100"},[_c('div',{staticClass:"alert fade alert-danger",class:{ show: _vm.errors.length },attrs:{"role":"alert"}},_vm._l((_vm.errors),function(error,i){return _c('div',{key:i,staticClass:"alert-text"},[_vm._v(" "+_vm._s(error)+" ")])}),0)]):_vm._e(),_c('v-col',{staticClass:"pb-0",attrs:{"md":"8","offset-md":"2"}},[_c('v-text-field',{attrs:{"dense":"","filled":"","label":"Name","solo":"","flat":"","color":"cyan","rules":[
          _vm.validateRules.required(_vm.productCreate.name, 'Name'),
          _vm.validateRules.minLength(_vm.productCreate.name, 'Name', 1),
          _vm.validateRules.maxLength(_vm.productCreate.name, 'Name', 100) ]},model:{value:(_vm.productCreate.name),callback:function ($$v) {_vm.$set(_vm.productCreate, "name", $$v)},expression:"productCreate.name"}}),_c('v-textarea',{attrs:{"dense":"","filled":"","label":"Description","solo":"","flat":"","row-height":"20","auto-grow":"","color":"cyan","rules":[
          _vm.validateRules.minLength(_vm.productCreate.description, 'Name', 1),
          _vm.validateRules.maxLength(_vm.productCreate.description, 'Name', 1024) ]},model:{value:(_vm.productCreate.description),callback:function ($$v) {_vm.$set(_vm.productCreate, "description", $$v)},expression:"productCreate.description"}}),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"pr-2 width-100",attrs:{"dense":"","filled":"","color":"cyan","label":"Reference","solo":"","flat":"","rules":[
            _vm.validateRules.minLength(_vm.productCreate.reference, 'Reference', 1),
            _vm.validateRules.maxLength(
              _vm.productCreate.reference,
              'Reference',
              100
            ) ]},model:{value:(_vm.productCreate.reference),callback:function ($$v) {_vm.$set(_vm.productCreate, "reference", $$v)},expression:"productCreate.reference"}}),_c('v-text-field',{staticClass:"pl-2 width-100",attrs:{"dense":"","filled":"","color":"cyan","label":"Barcode","solo":"","flat":"","readonly":""},model:{value:(_vm.barcode),callback:function ($$v) {_vm.barcode=$$v},expression:"barcode"}})],1),_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"custom-form-label"},[_vm._v("Tax Preference")]),_c('v-radio-group',{staticClass:"pt-0 mt-0 mb-2",attrs:{"dense":"","row":"","color":"cyan"},model:{value:(_vm.productCreate.taxable),callback:function ($$v) {_vm.$set(_vm.productCreate, "taxable", $$v)},expression:"productCreate.taxable"}},[_c('v-radio',{attrs:{"label":"Taxable","color":"cyan","value":true}}),_c('v-radio',{attrs:{"label":"Non-Taxable","color":"cyan","value":false}})],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"pr-2 width-100",attrs:{"dense":"","filled":"","color":"cyan","label":"Charges","solo":"","flat":"","rules":_vm.productCreate.product_type != 'goods'
              ? [_vm.validateRules.required(_vm.productCreate.charges, 'Charges')]
              : [],"type":"number","min":"0","max":"9999","prepend-inner-icon":"mdi-currency-usd"},on:{"keypress":function($event){return _vm.limitDecimal($event, _vm.productCreate.charges)}},model:{value:(_vm.productCreate.charges),callback:function ($$v) {_vm.$set(_vm.productCreate, "charges", _vm._n($$v))},expression:"productCreate.charges"}}),(_vm.productCreate.taxable)?_c('v-autocomplete',{staticClass:"pl-2 width-100",attrs:{"dense":"","color":"cyan","item-color":"cyan","items":_vm.taxList,"filled":"","label":"Tax","solo":"","flat":"","item-text":"name","item-value":"id","rules":[_vm.validateRules.required(_vm.productCreate.tax, 'Tax')]},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('No Tax(s) Found.')}})],1)],1)]},proxy:true}],null,false,2553023664),model:{value:(_vm.productCreate.tax),callback:function ($$v) {_vm.$set(_vm.productCreate, "tax", $$v)},expression:"productCreate.tax"}}):_c('v-autocomplete',{staticClass:"pl-2 width-100",attrs:{"items":_vm.taxExemptionList,"dense":"","filled":"","color":"cyan","item-color":"cyan","label":"Tax Exemption","solo":"","flat":"","item-text":"name","item-value":"id","rules":[
            _vm.validateRules.required(
              _vm.productCreate.tax_exemption,
              'Tax Exemption'
            ) ]},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('No Tax Exemption(s) Found.')}})],1)],1)]},proxy:true}],null,false,43245321),model:{value:(_vm.productCreate.tax_exemption),callback:function ($$v) {_vm.$set(_vm.productCreate, "tax_exemption", $$v)},expression:"productCreate.tax_exemption"}})],1),(_vm.getPermission('attachment:create'))?_c('div',[_c('label',{staticClass:"custom-form-label width-100"},[_vm._v("Images "),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2"},'v-icon',attrs,false),on),[_vm._v("mdi-progress-question")])]}}],null,false,2647039722)},[_c('span',[_vm._v("More than 5 images are not allowed")])])],1),_c('div',[_c('FileTemplate',{attrs:{"allowUpload":"","isMinDisplay":"","attachments":_vm.productCreate.product_images},on:{"update:attachments":function($event){return _vm.$set(_vm.productCreate, "product_images", $event)},"file:updated":_vm.updateImages}})],1)]):_vm._e()],1)],1),_c('v-col',{staticClass:"custom-sidebar-container grey lighten-3",attrs:{"cols":"12","md":"3","sm":"3"}},[_vm._l((_vm.fieldDescriptions),function(fieldInfo,indx){return [(fieldInfo.field == _vm.currentActiveField)?_c('div',{key:indx,staticClass:"help-sidebar"},[_c('div',{staticClass:"help-sidebar-header"},[(fieldInfo.title)?_c('h3',{staticClass:"section-header-title"},[_vm._v(" "+_vm._s(fieldInfo.title)+" ")]):_vm._e()]),(fieldInfo.description)?_c('p',{staticClass:"help-sidebar-description"},[_vm._v(" "+_vm._s(fieldInfo.description)+" ")]):_vm._e(),(fieldInfo.footer)?_c('h5',{staticClass:"help-sidebar-footer-title"},[_vm._v(" "+_vm._s(fieldInfo.footer)+" ")]):_vm._e(),(fieldInfo.footer_content)?_c('p',{staticClass:"help-sidebar-footer-text"},[_vm._v(" "+_vm._s(fieldInfo.footer_content)+" ")]):_vm._e()]):_vm._e()]})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }